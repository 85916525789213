import { UpdateParameterRequest } from "src/app/sdk";
import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React, { useCallback, useRef, useState } from "react";
import { useAsync } from "react-async";
import { FieldValues, SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { ParameterForm } from "src/app/components/Parameters/ParameterForm";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "src/app/contexts/store.context";
import { extractErrorMessage } from 'src/app/utils/error';

interface ParameterViewParams {
  parameterId: string;
}

export const parametersType: any[] = [];

const optionArray = [{ name: "null", value: "NULL" }];

const defaultValueARray = [
  { value: "NULL", label: "NULL" },
  { value: "NULL", label: "NULL" },
];

type FormProps<TFormValues extends FieldValues> = {
  onSubmit: SubmitHandler<TFormValues>;
  parameter: any;
  children: (methods: UseFormReturn<FieldValues>) => React.ReactNode;
  configArray: any[];
  setConfigArray: (array: any[]) => void;
};

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  onSubmit,
  children,
  parameter,
  configArray,
  setConfigArray,
}: FormProps<TFormValues>) => {
  const [selectDefaultValue, setSelectDefaultValue] = useState<any>(defaultValueARray);
  const modalRef = useRef<HTMLDivElement>(null);

  const methods = useForm({
    defaultValues: parameter || {},
  });

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <ParameterForm
        form={methods}
        parameter={parameter}
        configArray={configArray}
        setConfigArray={setConfigArray}
        selectDefaultValue={selectDefaultValue}
        setSelectDefaultValue={setSelectDefaultValue}
        modalRef={modalRef}
      />
      {children(methods)}
    </form>
  );
};

export const ParameterEdit = observer(() => {
  const history = useHistory();
  const { parameters: parametersStore } = useStore();
  const { getEntityAsync: getParameter } = parametersStore;
  const [configArray, setConfigArray] = useState<any>(optionArray);

  const { parameterId } = useParams<ParameterViewParams>();

  const fetchparameter = useCallback(async () => {
    return getParameter(parameterId);
  }, [getParameter, parameterId]);

  const { data } = useAsync({
    promiseFn: fetchparameter,
  });

  const parameter = useAsync({
    promiseFn: fetchparameter,
  });

  if (!parameter?.data) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen pt-16 pb-12 mt-48 bg-white">
        <LoadingSpinner type="TailSpin" color="black" height="50" />
      </div>
    );
  }
  const onSubmit = async (data: any) => {
    if (data.type === "SELECT" && !data.defaultValue) {
      data.close = false;
      throw new Error("This field is required : next to the 'Add Option'");
    }
    const updateParameterData: UpdateParameterRequest = {
      name: data.name,
      type: data.type,
      description: data.description,
      config: data.config,
      defaultValue: data.defaultValue === "NULL" ? null : data.defaultValue,
      isEnabled: data.isEnabled,
    };

    try {
      const update = await parametersStore.updateParameter(data.id, updateParameterData);
      toast.success(`Parameter '${update.name}' successfully updated`);
      history.push(`/parameters/active`);
    } catch (error: any) {
      toast.error(`Error updating parameter ${data.name} : ${extractErrorMessage(error)}`);
    }
  };

  return (
    <HeaderLayout
      left={
        <button
          type="button"
          onClick={() => history.push(parameter.data?.isEnabled ? "/parameters/active" : "/parameters/archived", { from: "parameters" })}
        >
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          Back
        </button>
      }
      title="Parameters"
    >
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        {!data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="flex flex-col w-full px-4 py-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
            <div className="xl:grid xl:grid-cols-2">
              <div className="xl:col-span-2 xl:pr-4 xl:border-gray-200">
                <div className="p-4 bg-white rounded shadow-md">
                  <Form parameter={parameter.data} onSubmit={onSubmit} configArray={configArray} setConfigArray={setConfigArray}>
                    {() => (
                      <div className="flex-shrink-0 px-2 py-3 border-t border-divider sm:px-6">
                        <div className="flex justify-between space-x-3">
                          <button
                            type="button"
                            className="px-4 py-2 text-sm font-medium bg-white rounded-md text-info hover:bg-white-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            onClick={() => history.goBack()}
                          >
                            Cancel
                          </button>
                          <input
                            type="submit"
                            value="Save"
                            className="ml-2 inset-y-0 right-0 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                          />
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
