import { Authenticate } from './services/authenticate/authenticate';
import { Dashboards } from './services/dashboards/dashboards';
import { Engines } from './services/engines/engines';
import { Campaigns } from './services/optimize/campaigns/campaigns';
import { Parameters } from './services/optimize/parameters/parameters';
import { Variations } from './services/optimize/variations/variations';
import { Stores } from './services/stores/stores';
import { Users } from './services/users/users';

/**
 * SDK for Recommendation Engine by koji.fr
 */
export class RecommendationSdk {
  public auth: Authenticate;
  public users: Users;
  public stores: Stores;
  public dashboards: Dashboards;
  public engines: Engines;
  public parameters: Parameters;
  public campaigns: Campaigns;
  public variations: Variations;

  public endpoint: string | undefined;

  /**
   *
   * @param {OptionRecommendationSdk} options
   */
  constructor(options?: OptionRecommendationSdk) {
    if (options) {
      Object.assign(this, options);
    }
    this.auth = new Authenticate(this);
    this.users = new Users(this);
    this.stores = new Stores(this);
    this.dashboards = new Dashboards(this);
    this.engines = new Engines(this);
    this.parameters = new Parameters(this);
    this.campaigns = new Campaigns(this);
    this.variations = new Variations(this);
  }

  setEndpoint(endpoint: string): void {
    this.endpoint = endpoint;
  }
}

export interface OptionRecommendationSdk {
  endpoint?: string;
  defaultStore?: number;
  useMocks?: boolean;
}
