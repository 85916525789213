import axios, { AxiosInstance } from 'axios';
import { RecommendationSdk } from '../../recommendation-sdk';
import { AuthenticateResponse, AuthenticateUserRequest } from './models';

export class Authenticate {
  protected readonly recoInstance: AxiosInstance;

  constructor(sdk: RecommendationSdk) {
    this.recoInstance = axios.create({
      baseURL: sdk.endpoint,
      withCredentials: true,
    });
  }

  /**
   * Try to get the user matched to the Acrelec user or create one, and return auth tokens
   * @param {string} authorizationCode data to retrieve Acrelec user and return the tokens
   */
  async ssoUser(authorizationCode: string): Promise<AuthenticateResponse> {
    return this.recoInstance.post(`/users/sso-authenticate`, {authorizationCode}).then(res => {
      return res.data;
    });
  }

  /**
   * Authenticate with user Account
   * @param {AuthenticateUserRequest} authData data for authenticate User
   * @returns
   */
  async user(authData: AuthenticateUserRequest): Promise<AuthenticateResponse> {
    return this.recoInstance.post(`/users/authenticate`, authData).then(res => {
      return res.data;
    });
  }

  /**
   * Log out user
   */
  async userLogout(): Promise<void> {
    return this.recoInstance.post(`/users/logout`).then(response => response.data);
  }
}
