import { DashboardThemeEnum } from "src/app/sdk";
import { Instance, types } from "mobx-state-tree";
import { NumberBaseEntity } from "../utils/number-entity.store";

import { DateTimeType } from "./date-time.custom";

export const DashboardModel = NumberBaseEntity.named("DashboardModel")
  .props({
    id: types.identifierNumber,
    metabaseDashboardId: types.number,
    name: types.string,
    description: types.maybeNull(types.string),
    embedUrl: types.maybe(types.string),
    isTitled: types.boolean,
    isBordered: types.boolean,
    theme: types.enumeration(Object.values(DashboardThemeEnum)),
    createdAt: DateTimeType,
    updatedAt: DateTimeType,
  })
  .actions((self) => ({}))
  .views((self) => ({}))
  .actions((self) => ({}));

export type DashboardModelType = Instance<typeof DashboardModel>;
