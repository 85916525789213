import { PaginatedResult, StoreObject, UpdateCampaignRequest, VariationObject } from "src/app/sdk";
import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import { VariationModel } from "src/app/models/variation.model";
import { RootSdkType } from "src/app/stores/root.store";
import { StringBaseEntity } from "../utils/string-entity.store";
import { DateTimeType } from "./date-time.custom";

export const CampaignModel = StringBaseEntity.named("CampaignModel")
  .props({
    id: types.identifier,
    rawCampaign: types.frozen<StoreObject>(),
    name: types.string,
    description: types.string,
    condition: types.frozen(),
    isEnabled: types.boolean,
    parameters: types.array(types.string),
    variations: types.maybe(types.array(types.string)),
    loadedVariations: types.maybe(types.array(VariationModel)),
    createdAt: DateTimeType,
    updatedAt: DateTimeType,
  })
  .actions((self) => ({
    loadVarations: flow(function* () {
      const { microservice } = getEnv<RootSdkType>(self);
      const variations: PaginatedResult<VariationObject> = yield microservice.variations.getAll(self.id, { limit: 1000 });
      self.loadedVariations = cast(variations.items);
      return self.loadedVariations;
    }),
  }))
  .actions((self) => ({
    update: flow(function* (updateData: UpdateCampaignRequest) {
      Object.assign(self, updateData);
      const { microservice } = getEnv<RootSdkType>(self);
      const result = yield microservice.campaigns.update(self.id, updateData);
      Object.assign(self, result);
      self.loadVarations();
    }),
    afterAttach() {
      self.loadVarations();
    },
  }));

export type CampaignModelType = Instance<typeof CampaignModel>;
