import axios, { AxiosInstance } from 'axios';
import { RecommendationSdk } from '../../recommendation-sdk';
import {
  CreateStoreRequest,
  FindAtpStoresRequest,
  FindStoresRequest,
  StoreObject,
  UpdateStoreRequest,
  FindStoresResponse,
} from './models';

export class Stores {
  protected readonly axiosInstance: AxiosInstance;

  constructor(sdk: RecommendationSdk) {
    this.axiosInstance = axios.create({
      baseURL: sdk.endpoint,
      withCredentials: true,
    });
  }

  /**
   * Get current Store
   */
  async getCurrent(): Promise<StoreObject> {
    return this.axiosInstance.get(`/stores/me`).then((res) => res.data);
  }

  /**
   * Get All stores
   * @param {FindStoresRequest} options
   */
  async getAll(options?: FindStoresRequest): Promise<FindStoresResponse> {
    return this.axiosInstance
      .get(`/stores`, {
        params: {
          skip: options?.skip,
          limit: options?.limit,
          query: options?.query,
        },
      })
      .then((res) => res.data);
  }

  /**
   * Get All ATP stores
   * @param {FindAtpStoresRequest} findAtpStoresData
   */
  async getAllAtp(findAtpStoresData?: FindAtpStoresRequest): Promise<StoreObject[]> {
    return this.axiosInstance
      .get(`/stores/atp`, {
        params: findAtpStoresData,
      })
      .then((res) => res.data);
  }

  /**
   * Get Store by Id
   * @param {number} storeId Id of store
   */
  async getOne(storeId?: number): Promise<StoreObject> {
    return this.axiosInstance.get(`/stores/${storeId}`).then((res) => res.data);
  }

  /**
   * Create a new Store
   * @param {CreateStoreRequest} storeData Data for create store
   */
  async create(storeData: CreateStoreRequest): Promise<StoreObject> {
    return this.axiosInstance.post(`/stores`, storeData).then((res) => res.data);
  }

  /**
   * Update a store
   * @param {number} storeId Id of store
   * @param {UpdateStoreRequest} storeData New data for update store
   */
  async update(storeId: number, storeData: UpdateStoreRequest): Promise<StoreObject> {
    return this.axiosInstance.patch(`/stores/${storeId}`, storeData).then((res) => res.data);
  }

  /**
   * Delete a store
   * @param storeId Id of store
   */
  async delete(storeId: number): Promise<StoreObject> {
    return this.axiosInstance.delete(`/stores/${storeId}`).then((res) => res.data);
  }
}
