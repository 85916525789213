import axios, { AxiosInstance } from 'axios';
import { RecommendationSdk } from '../../recommendation-sdk';
import {
  CreateUserRequest,
  FindUsersRequest,
  UpdateUserRequest,
  UserObject,
  FindUsersResponse,
} from './models';

export class Users {
  protected readonly axiosInstance: AxiosInstance;

  constructor(sdk: RecommendationSdk) {
    this.axiosInstance = axios.create({
      baseURL: sdk.endpoint,
      withCredentials: true,
    });
  }

  /**
   * Get current user
   */
  async getCurrent(): Promise<UserObject> {
    return this.axiosInstance.get(`/users/me`).then(res => res.data);
  }

  /**
   * Get all users of Recommendations
   * @param {FindUsersRequest} options
   */
  async getAll(options?: FindUsersRequest): Promise<FindUsersResponse[]> {
    return this.axiosInstance
      .get(`/users`, {
        params: {
          skip: options?.skip,
          limit: options?.limit,
          query: options?.query,
        },
      })
      .then(res => res.data);
  }

  /**
   * Get One user by Id
   * @param {number} userId ID of user
   */
  async getOne(userId: number): Promise<UserObject> {
    return this.axiosInstance.get(`/users/${userId}`).then(res => res.data);
  }

  /**
   * Create a new User
   * @param {CreateUserRequest} userData data for create user
   */
  async create(userData: CreateUserRequest): Promise<UserObject> {
    return this.axiosInstance.post(`/users`, userData).then(res => res.data);
  }

  /**
   * Update a User by Id
   * @param {number} userId Id of user
   * @param {UpdateUserRequest} userData data for update user
   */
  async update(userId: number, userData: UpdateUserRequest): Promise<UserObject> {
    return this.axiosInstance.patch(`/users/${userId}`, userData).then(res => res.data);
  }

  /**
   * Delete user by Id
   * @param {number} userId Id of User
   */
  async delete(userId: number): Promise<UserObject> {
    return this.axiosInstance.delete(`/users/${userId}`).then(res => res.data);
  }
}
