import axios, { AxiosInstance } from 'axios';
import {
  CreateEngineRequest,
  EngineObject,
  FindEnginesRequest,
  UpdateEngineRequest,
  FindEnginesResponse,
} from './models';
import { RecommendationSdk } from '../../recommendation-sdk';

export class Engines {
  protected readonly axiosInstance: AxiosInstance;

  constructor(sdk: RecommendationSdk) {
    this.axiosInstance = axios.create({
      baseURL: sdk.endpoint,
      withCredentials: true,
    });
  }

  /**
   * Get all engines
   */
  async getAll(options?: FindEnginesRequest): Promise<FindEnginesResponse> {
    return this.axiosInstance.get(`/engines`, {
      params: {
        skip: options?.skip,
        limit: options?.limit,
        query: options?.query,
      },
    }).then(res => res.data);
  }

  /**
   * Get engine by id
   * @param {number} engineId Id of Dashboard
   */
  async getOne(engineId?: string): Promise<EngineObject> {
    return this.axiosInstance.get(`/engines/${engineId}`).then(res => res.data);
  }

  /**
   * Create a new engine
   * @param {CreateEngineRequest} engineData Data needed to create the engine
   */
  async create(engineData: CreateEngineRequest): Promise<EngineObject> {
    return this.axiosInstance.post(`/engines`, engineData).then(res => res.data);
  }

  /**
   * Update an engine
   * @param {number} engineId Id of engine
   * @param {UpdateEngineRequest} engineData New data to update the engine
   */
  async update(engineId: string, engineData: UpdateEngineRequest): Promise<EngineObject> {
    return this.axiosInstance.patch(`/engines/${engineId}`, engineData).then(res => res.data);
  }

  /**
   * Delete an engine
   * @param engineId Id of the engine
   */
  async delete(engineId: string): Promise<EngineObject> {
    return this.axiosInstance.delete(`/engines/${engineId}`).then(res => res.data);
  }
}
