import {
  CampaignObject,
  CreateCampaignRequest,
  GetCampaignsRequest,
  UpdateCampaignRequest,
} from './models';
import axios, { AxiosInstance } from 'axios';
import { RecommendationSdk } from '../../../recommendation-sdk';
import { PaginatedResult } from '../../../common';

export class Campaigns {
  protected readonly axiosInstance: AxiosInstance;

  constructor(sdk: RecommendationSdk) {
    this.axiosInstance = axios.create({
      baseURL: sdk.endpoint,
      withCredentials: true,
    });
  }

  /**
   * Get all campaigns, results are paginated
   * @param {GetVariationsRequest} pagination data
   */
  async getAll(options?: GetCampaignsRequest): Promise<PaginatedResult<CampaignObject>> {
    return this.axiosInstance
      .get(`/optimize/campaigns`, {
        params: {
          ...options,
        },
      })
      .then(res => res.data);
  }

  /**
   * Get campaign by id
   * @param {string} campaignId Id of the campaign
   */
  async getOne(campaignId?: string): Promise<CampaignObject> {
    return this.axiosInstance.get(`/optimize/campaigns/${campaignId}`).then(res => res.data);
  }

  /**
   * Create a new campaign
   * @param {CreateCampaignRequest} campaignData Data needed to create the campaign
   */
  async create(campaignData: CreateCampaignRequest): Promise<CampaignObject> {
    return this.axiosInstance.post(`/optimize/campaigns`, campaignData).then(res => res.data);
  }

  /**
   * Update a campaign
   * @param {string} campaignId Id of the campaign
   * @param {UpdateCampaignRequest} campaignData New data to update the campaign
   */
  async update(campaignId: string, campaignData: UpdateCampaignRequest): Promise<CampaignObject> {
    return this.axiosInstance
      .patch(`/optimize/campaigns/${campaignId}`, campaignData)
      .then(res => res.data);
  }

  /**
   * Delete a campaign
   * @param {string} campaignId Id of the campaign
   */
  async delete(campaignId: string): Promise<CampaignObject> {
    return this.axiosInstance.delete(`/optimize/campaigns/${campaignId}`).then(res => res.data);
  }
}
