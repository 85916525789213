import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useAsync } from "react-async";
import { useHistory, useParams } from "react-router-dom";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "src/app/contexts/store.context";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FieldValues, SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import { EngineForm } from "src/app/components/Engines/EngineForm";
import { UpdateEngineRequest } from "src/app/sdk";
import toast from "react-hot-toast";
import { extractErrorMessage } from 'src/app/utils/error';

interface EngineViewParams {
  engineId: string;
}

type FormProps<TFormValues extends FieldValues> = {
  onSubmit: SubmitHandler<TFormValues>;
  engine: any;
  children: (methods: UseFormReturn<FieldValues>) => React.ReactNode;
};

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  onSubmit,
  children,
  engine,
}: FormProps<TFormValues>) => {

  const methods = useForm({
    defaultValues: engine || {},
  });

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <EngineForm
        form={methods}
      />
      {children(methods)}
    </form>
  );
};

export const EngineEdit = observer(() => {
  const history = useHistory();
  const { engines: enginesStore } = useStore();
  const { getEntityAsync: getEngine } = enginesStore;

  const { engineId } = useParams<EngineViewParams>();

  const fetchEngine = useCallback(async () => {
    return getEngine(engineId);
  }, [getEngine, engineId]);

  const { data } = useAsync({
    promiseFn: fetchEngine,
  });

  const engine = useAsync({
    promiseFn: fetchEngine,
  });

  if (!engine?.data) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen pt-16 pb-12 mt-48 bg-white">
        <LoadingSpinner type="TailSpin" color="black" height="50" />
      </div>
    );
  }
  const onSubmit = async (data: any) => {
    const updateEngineData: UpdateEngineRequest = {
      name: data.name,
      key: data.key,
      description: data.description,
      url: data.url,
    };

    try {
      const update = await enginesStore.updateEngine(data.id, updateEngineData);
      toast.success(`Engine '${update.name}' successfully updated`);
      history.push(`/engines`);
    } catch (error: any) {
      toast.error(`Error updating engine ${data.name} : ${extractErrorMessage(error)}`);
    }
  };

  return (
    <HeaderLayout
      left={
        <button type="button" onClick={() => history.goBack()}>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          Back
        </button>
      }
      title="Engines"
    >
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        {!data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="flex flex-col w-full px-4 py-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
            <div className="xl:grid xl:grid-cols-2">
              <div className="xl:col-span-2 xl:pr-4 xl:border-gray-200">
                <div className="p-4 bg-white rounded shadow-md">
                  <Form engine={engine.data} onSubmit={onSubmit}>
                    {() => (
                      <div className="flex-shrink-0 px-2 py-3 border-t border-divider sm:px-6">
                        <div className="flex justify-between space-x-3">
                          <button
                            type="button"
                            className="px-4 py-2 text-sm font-medium bg-white rounded-md text-info hover:bg-white-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            onClick={() => history.goBack()}
                          >
                            Cancel
                          </button>
                          <input
                            type="submit"
                            value="Save"
                            className="ml-2 inset-y-0 right-0 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                          />
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
