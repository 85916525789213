import { faStoreAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { useCallback } from "react";
import Chart, { Props as ApexChartReactProps } from "react-apexcharts";
import { useAsync } from "react-async";
import QueryBuilder, { RuleGroupTypeAny } from "react-querybuilder";
import { useHistory } from "react-router-dom";

import { ParametersSelector } from "../Parameters/ParametersSelector";
import { queryBuilderFields, tailwindClassNames, ValueEditor } from "./QueryEditor";
import { useStore } from "src/app/contexts/store.context";
import { CampaignModelType } from "src/app/models/campaign.model";

interface CampaignDetailsProps {
  campaign: CampaignModelType;
}

export const CampaignDetails = observer((props: CampaignDetailsProps) => {
  const { campaign } = props;

  const {
    variations: { getVariations },
  } = useStore();

  const chartConfig: ApexChartReactProps = {
    type: "donut",
    series: [],
    options: {
      labels: [],
    },
  };

  const fetchCampaignVariations = useCallback(async () => {
    if (campaign.id) {
      return getVariations(campaign.id);
    }
  }, [campaign.id, getVariations]);

  const campaignVariations = useAsync({
    promiseFn: fetchCampaignVariations,
  });

  campaignVariations?.data?.forEach((value: any) => {
    // @ts-ignore
    chartConfig.series?.push(parseInt(value.weight, 10));
    chartConfig.options?.labels?.push(value.name);
  });
  const history = useHistory();

  const goToCampaignEditPage = (campaign: CampaignModelType) => () => history.push(`/campaigns/campaign/edit/${campaign.id}`);

  return (
    <div>
      <div className="p-2 md:flex md:items-center md:justify-between md:space-x-4">
        <div className="w-full space-y-6">
          <div className="flex items-center justify-between w-full">
            <div>
              <h1 className="inset-y-0 left-0 text-2xl font-bold capitalize w-4/10 text-primary ">
                <FontAwesomeIcon className="mr-3 text-primary" icon={faStoreAlt} />
                {campaign.name}
              </h1>
              <p className="mt-2 text-sm text-gray-500">
                <span className="font-medium ">API ID: </span>
                {campaign.id}
              </p>
            </div>
            <button
              className="  ml-2 inset-y-0 right-0 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
              onClick={goToCampaignEditPage(campaign)}
            >
              Edit
            </button>
          </div>
          <div className="overflow-auto border-t border-gray-200">
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Name:</span> {campaign.name}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Description:</span> {campaign.description}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Filters:</span>
              </span>
            </p>
            {(campaign.condition as RuleGroupTypeAny).rules?.length ? (
              <div className="mt-3 text-sm">
                <QueryBuilder
                  fields={queryBuilderFields}
                  query={{ disabled: true, ...campaign.condition}}
                  controlClassnames={{
                    ...tailwindClassNames,
                    addGroup: "hidden",
                    addRule: "hidden",
                    removeGroup: "hidden",
                    removeRule: "hidden"
                  }}
                  showCombinatorsBetweenRules
                  controlElements={{
                    valueEditor: ValueEditor
                  }}
                />
              </div>
            ) : (
              <div className="mt-3 text-sm">
                <span className="font-medium">No filters</span>
              </div>
            )}
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Is active:</span> {campaign.isEnabled === true ? "TRUE" : "FALSE"}
              </span>
            </p>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Parameters:</span>
              </span>
            </p>
            <div className="mt-3 text-sm">
              <ParametersSelector isDisabled value={campaign.parameters} onChange={() => undefined} isMulti />
            </div>
            <p className="mt-3 text-sm">
              <span className="font-medium">
                <span className="font-bold">Variations:</span>
              </span>
            </p>
            {campaignVariations && campaignVariations.data && campaignVariations.data?.length > 0 ? (
              <>
                <div className="w-1/3">
                  <Chart {...chartConfig} />
                </div>
                {campaignVariations.data.map((campaignVariation, index) => (
                  <div key={campaignVariation.id} className="pt-1 ml-4 border-t border-gray-200">
                    <p className="mt-3 text-sm">
                      <span className="font-medium">
                        <span className="font-bold">Variation {index + 1}</span>
                      </span>
                    </p>
                    <div className="pt-1 ml-4">
                      <p className="mt-3 text-sm">
                        <span className="font-medium">
                          <span className="font-bold">ID:</span> {campaignVariation.id}
                        </span>
                      </p>
                      <p className="mt-3 text-sm">
                        <span className="font-medium">
                          <span className="font-bold">Name:</span> {campaignVariation.name}
                        </span>
                      </p>
                      <p className="mt-3 text-sm">
                        <span className="font-medium">
                          <span className="font-bold">Description:</span> {campaignVariation.description}
                        </span>
                      </p>
                      <p className="mt-3 text-sm">
                        <span className="font-medium">
                          <span className="font-bold">Weight:</span> {campaignVariation.weight}
                        </span>
                      </p>
                      <p className="mt-3 text-sm">
                        <span className="font-medium">
                          <span className="font-bold">Is default:</span> {campaignVariation.isDefault === true ? "TRUE" : "FALSE"}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="mt-3 text-sm">
                <span className="font-medium">No variations added</span>
              </div>
            )}
          </div>
          <div className="pt-3 border-t border-gray-200">
            <p className="mt-2 text-xs">
              <span className="font-medium">
                <span className="font-bold">Created:</span> {campaign.createdAt.toLocaleString({ ...DateTime.DATETIME_MED, month: "long" })}
              </span>
            </p>
            <p className="mt-3 text-xs">
              <span className="font-medium">
                <span className="font-bold">Last Updated:</span>{" "}
                {campaign.updatedAt.toLocaleString({ ...DateTime.DATETIME_MED, month: "long" })}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
