import { AuthenticateResponse } from "src/app/sdk";
import { AuthenticateUserRequest } from "src/app/sdk";
import { flow, getEnv, types } from "mobx-state-tree";
import { RootSdkType } from "src/app/stores/root.store";
import { UserModel } from "../models/user.model";

export const AuthenticationStore = types
  .model("AuthenticationStore", {
    currentUser: types.maybeNull(UserModel),
    isLoggedIn: types.optional(types.boolean, () => false),
    isAtpUser: types.optional(types.boolean, () => false),
    atpIdToken: types.maybe(types.string),
  })
  .actions((self) => ({
    resetAuthStore() {
      self.isLoggedIn = false;
      self.isAtpUser = false;
      self.currentUser = null;
      self.atpIdToken = undefined;
      localStorage.removeItem("is-logged");
    },
  }))
  .actions((self) => ({
    authenticate: flow(function* _(data: AuthenticateUserRequest) {
      const { microservice } = getEnv<RootSdkType>(self);
      try {
        const authenticateUserResponse: AuthenticateResponse = yield microservice.auth.user(data);
        self.isLoggedIn = true;
        localStorage.setItem("is-logged", "true");
        return authenticateUserResponse;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }),
    ssoAuthenticate: flow(function* _(authorizationCode: string) {
      const { microservice } = getEnv<RootSdkType>(self);
      try {
        const user: AuthenticateResponse = yield microservice.auth.ssoUser(authorizationCode);
        self.isLoggedIn = true;
        self.isAtpUser = true;
        localStorage.setItem("is-logged", "true");
        //self.atpIdToken = user.atpIdToken;
        return user;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }),
    fetchCurrentUser: flow(function* _() {
      const { microservice } = getEnv<RootSdkType>(self);
      try {
        const user = yield microservice.users.getCurrent();
        self.currentUser = user;
        if (!!user.acrelecId) {
          self.isAtpUser = true;
        }
        return user;
      } catch (error) {
        self.isLoggedIn = false;
        console.error(error);
      }
    }),
    clearCurrentUser() {
      self.currentUser = null;
    },
    logout: flow(function* () {
      const { microservice } = getEnv<RootSdkType>(self);
      try {
        return yield microservice.auth.userLogout().then(() => {
          if (self.atpIdToken) {
            const redirectUrl = `${window.location.protocol}//${window.location.host}/login`;
            const atpIdToken = self.atpIdToken;
            const ssoAuthorityUrl = window.__RUNTIME_CONFIG__.REACT_APP_ACRELEC_SSO_URL;

            const url = `${ssoAuthorityUrl}/logout?redirectUrl=${redirectUrl}&atpIdToken=${atpIdToken}`;

            window.location.replace(url);
          }
          self.resetAuthStore();
        });
      } catch (e) {
        console.error("LOGOUT ERROR", e);
        return undefined;
      }
    }),
  }))
  .views((self) => ({
    get isAuthenticated() {
      return self.isLoggedIn && !!self.currentUser;
    },
  }));

export const authenticationStore = AuthenticationStore.create({
  currentUser: null,
  isLoggedIn: localStorage.getItem("is-logged") ? true : false,
  isAtpUser: false,
});
