import { ParameterTypeEnum, ParameterConfigObject } from "src/app/sdk";
import { Instance, types } from "mobx-state-tree";
import { StringBaseEntity } from '../utils/string-entity.store';
import { DateTimeType } from "./date-time.custom";

export const ParameterModel = StringBaseEntity.named("ParameterModel")
  .props({
    id: types.identifier,
    name: types.string,
    type: types.enumeration(Object.values(ParameterTypeEnum)),
    description: types.string,
    config: types.frozen<ParameterConfigObject>(),
    defaultValue: types.maybeNull(types.union(types.string, types.number, types.integer, types.boolean, types.array(types.string))),
    isEnabled: types.boolean,
    createdAt: DateTimeType,
    updatedAt: DateTimeType,
  })
  .actions((self) => ({}))
  .views((self) => ({}))
  .actions((self) => ({}));

export type ParameterModelType = Instance<typeof ParameterModel>;
