import { RecommendationSdk } from "src/app/sdk";
import { applySnapshot, getSnapshot, Instance, types } from "mobx-state-tree";
import { authenticationStore, AuthenticationStore } from "src/app/stores/authentication.store";
import { campaignsStore, CampaignsStore } from "src/app/stores/campaigns.store";
import { dashboardsStore, DashboardsStore } from "src/app/stores/dashboards.store";
import { enginesStore, EnginesStore } from "src/app/stores/engines.store";
import { parametersStore, ParametersStore } from "src/app/stores/parameters.store";
import { restaurantsStore, RestaurantsStore } from "src/app/stores/restaurants.store";
import { variationsStore, VariationsStore } from "src/app/stores/variations.store";

export const recommendationSdk = new RecommendationSdk({
  endpoint: '/api',
});

export type RootSdkType = {
  microservice: RecommendationSdk;
};

export const RootStore = types
  .model("RootStore", {
    authentication: AuthenticationStore,
    engines: EnginesStore,
    restaurants: RestaurantsStore,
    campaigns: CampaignsStore,
    dashboards: DashboardsStore,
    parameters: ParametersStore,
    variations: VariationsStore,
  })
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self.authentication, initialState);
      },
    };
  });

export type RootStoreType = Instance<typeof RootStore>;

export const createStore = () => {
  return RootStore.create(
    {
      authentication: authenticationStore as any,
      restaurants: restaurantsStore as any,
      campaigns: campaignsStore as any,
      dashboards: dashboardsStore as any,
      engines: enginesStore as any,
      parameters: parametersStore as any,
      variations: variationsStore as any,
    },
    {
      microservice: recommendationSdk,
    } as RootSdkType
  );
};

export const rootStore = createStore();
