import { FindDashboardsResponse } from "src/app/sdk";
import {
  CreateDashboardRequest,
  DashboardObject,
  FindDashboardsRequest,
  UpdateDashboardRequest,
} from "src/app/sdk";
import { cast, flow, getEnv, types } from "mobx-state-tree";
import { DashboardModel } from "src/app/models/dashboard.model";
import { PaginatedModels } from "src/app/models/paginated-models.model";
import { RootSdkType } from "src/app/stores/root.store";
import { createNumberEntityReference, createNumberEntityStore } from "src/app/utils/number-entity.store";

export const DashboardReference = createNumberEntityReference("dashboards", DashboardModel);

export const DashboardsStore = createNumberEntityStore("Dashboard", DashboardModel)
  .props({
    dashboards: types.maybe(PaginatedModels(DashboardReference)),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (dashboardId: number) {
      const { microservice } = getEnv<RootSdkType>(self);

      const result: DashboardObject = yield microservice.dashboards.getOne(dashboardId);

      return DashboardModel.create({
        rawDashboard: result,
        ...(result as any),
      });
    }),
    findDashboards: flow(function* (findDashboardsData: FindDashboardsRequest) {
      const { microservice } = getEnv<RootSdkType>(self);
      const result: FindDashboardsResponse = yield microservice.dashboards.getAll(findDashboardsData);

      const dashboards = result.items.map((dashboard) => {
        const entityModel = self.cacheEntity(
          DashboardModel.create({
            rawDashboard: dashboard,
            ...(dashboard as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.dashboards = cast({
        ...result,
        data: cast(dashboards as any),
      });

      return self.dashboards as PaginatedModels<typeof DashboardModel>;
    }),
    createDashboard: flow(function* (createDashboardData: CreateDashboardRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.dashboards.create(createDashboardData);
      } catch (error) {
        console.error("ERROR CREATING STORE:", error);
        throw error;
      }
    }),
    updateDashboard: flow(function* (dashboardId, updateDashboardData: UpdateDashboardRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.dashboards.update(dashboardId, updateDashboardData);
      } catch (error) {
        console.error("ERROR UPDATING STORE:", error);
        throw error;
      }
    }),
    deleteDashboard: flow(function* (dashboardId: number) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.dashboards.delete(dashboardId);
      } catch (error) {
        console.error("ERROR DELETING STORE:", error);
        throw error;
      }
    }),
  }));

export const dashboardsStore = DashboardsStore.create();

// export const DashboardsStore = types
//   .model("DashboardsStore", {
//     dashboards: types.array(DashboardModel),
//   })
//   .actions((self) => ({
//     findDashboards: flow(function* () {
//       const { microservice } = getEnv<RootSdkType>(self);

//       const result: DashboardObject[] = yield microservice.dashboards.getAll();
//       const dashboards = result.map((dashboard) => {
//         return DashboardModel.create({
//           ...(dashboard as any),
//         });
//       });

//       self.dashboards = cast(dashboards);

//       return dashboards;
//     }),
//     getDashboard: flow(function* (dashboardId: number) {
//       const { microservice } = getEnv<RootSdkType>(self);

//       const dashboard: DashboardObject = yield microservice.dashboards.getOne(dashboardId);

//       return DashboardModel.create({
//         ...(dashboard as any),
//       });
//     }),
//     createDashboard: flow(function* (createDashboardData: CreateDashboardRequest) {
//       const { microservice } = getEnv<RootSdkType>(self);

//       try {
//         return yield microservice.dashboards.create(createDashboardData);
//       } catch (error) {
//         console.error("ERROR CREATING DASHBOARD:", error);
//         throw error;
//       }
//     }),
//     updateDashboard: flow(function* (dashboardId, updateDashboardData: UpdateDashboardRequest) {
//       const { microservice } = getEnv<RootSdkType>(self);

//       try {
//         return yield microservice.dashboards.update(dashboardId, updateDashboardData);
//       } catch (error) {
//         console.error("ERROR UPDATING DASHBOARD:", error);
//         throw error;
//       }
//     }),
//     deleteDashboard: flow(function* (dashboardId: number) {
//       const { microservice } = getEnv<RootSdkType>(self);

//       try {
//         return yield microservice.dashboards.delete(dashboardId);
//       } catch (error) {
//         console.error("ERROR DELETING DASHBOARD:", error);
//         throw error;
//       }
//     }),
//   }));

// export const dashboardsStore = DashboardsStore.create();
