import {
  CreateParameterRequest,
  GetParametersRequest,
  ParameterObject,
  UpdateParameterRequest,
} from './models';
import axios, { AxiosInstance } from 'axios';
import { RecommendationSdk } from '../../../recommendation-sdk';
import { PaginatedResult } from '../../../common';

export class Parameters {
  protected readonly axiosInstance: AxiosInstance;

  constructor(sdk: RecommendationSdk) {
    this.axiosInstance = axios.create({
      baseURL: sdk.endpoint,
      withCredentials: true,
    });
  }

  /**
   * Get all parameters, results are paginated
   * @param {GetVariationsRequest} pagination data
   */
  async getAll(options?: GetParametersRequest): Promise<PaginatedResult<ParameterObject>> {
    return this.axiosInstance
      .get(`/optimize/parameters`, {
        params: {
          ...options,
        },
      })
      .then(res => res.data);
  }

  /**
   * Get parameter by id
   * @param {string} campaignId Id of the parameter
   */
  async getOne(parameterId?: string): Promise<ParameterObject> {
    return this.axiosInstance.get(`/optimize/parameters/${parameterId}`).then(res => res.data);
  }

  /**
   * Create a new parameter
   * @param {CreateParameterRequest} parameterData Data needed to create the parameter
   */
  async create(parameterData: CreateParameterRequest): Promise<ParameterObject> {
    return this.axiosInstance.post(`/optimize/parameters`, parameterData).then(res => res.data);
  }

  /**
   * Update a parameter
   * @param {string} campaignId Id of the parameter
   * @param {UpdateParameterRequest} parameterData New data to update the parameter
   */
  async update(
    parameterId: string,
    parameterData: UpdateParameterRequest,
  ): Promise<ParameterObject> {
    return this.axiosInstance
      .patch(`/optimize/parameters/${parameterId}`, parameterData)
      .then(res => res.data);
  }

  /**
   * Delete a parameter
   * @param {string} campaignId Id of the parameter
   */
  async delete(parameterId: string): Promise<ParameterObject> {
    return this.axiosInstance.delete(`/optimize/parameters/${parameterId}`).then(res => res.data);
  }
}
