import clsx from "clsx";
import { ElementRef, useEffect, useRef, useState } from "react";
import Chart, { Props as ApexChartReactProps } from "react-apexcharts";
import { Controller, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import QueryBuilder, { ActionElement } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.scss";

import { UpdateCampaignRequest } from "src/app/sdk";

import { DataGridForm } from "../DataGrid/DataGridForm";
import { ParametersSelector } from "../Parameters/ParametersSelector";
import {
  tailwindClassNames,
  ValueEditor,
  queryBuilderFields
} from "./QueryEditor";
import { useStore } from "src/app/contexts/store.context";
import { DataGridArchive } from "../DataGrid/DataGridArchive";
import { VariationForm } from "./VariationForm";
import { VariationAccordionForm } from "./VariationsAccorionForm";

export interface CampaignFormProps {
  form: UseFormReturn;
  parameters: any;
  campaignVariations?: any;
  onSubmitVariationForm?: any;
  onVariationDelete?: any;
}

export const CampaignForm = (props: CampaignFormProps) => {
  const { campaigns: campaignsStore } = useStore();
  const [parametersArray, setParametersArray] = useState<any>([]);
  const variationFormRef = useRef<ElementRef<typeof DataGridForm>>(null);

  const { updateCampaign } = campaignsStore;
  const {
    form,
    parameters,
    campaignVariations,
    onSubmitVariationForm,
    onVariationDelete
  } = props;

  const data = form.watch();

  const archiveRef = useRef<ElementRef<typeof DataGridArchive>>(null);

  const onArchive = async (data: any) => {
    const request: UpdateCampaignRequest = {};
    request.isEnabled = false;
    await updateCampaign(data.id, request);
    toast.success(`Campaign '${data.name}' archived !`);
  };

  useEffect(() => {
    if (data.parameters?.length > 0) {
      const selectedParameters = parameters.data.data
        .filter((_: any) => data.parameters.includes(_.id))
        .map((_: any) => ({
          id: _.id,
          value: _.defaultValue,
          name: _.name,
          type: _.type,
          config: _.config,
        }))
      setParametersArray(selectedParameters);
    }
  }, [data.parameters, parameters.data.data]);

  const chartConfig: ApexChartReactProps = {
    type: "donut",
    series: [],
    options: {
      labels: [],
    },
  };
  campaignVariations?.data?.forEach((value: any) => {
    chartConfig.series?.push(parseInt(value.weight, 10) as any);
    chartConfig.options?.labels?.push(value.name);
  });

  return (
    <>
      <DataGridArchive
        ref={archiveRef}
        onArchive={onArchive}
        title="Archive a Campaign"
        description={(data) => `Are you sure you wish to archive campaign '${data?.name || ""}' ?`}
      />
      {data.id && (
        <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
              ID
            </label>
          </div>
          <div className="col-span-2">
            <input
              defaultValue={data.id}
              disabled
              type="text"
              className="block w-full text-gray-500 rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
            />
          </div>
        </div>
      )}
      <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
            Name
          </label>
        </div>
        <div className="col-span-2">
          <input
            {...form?.register("name")}
            type={"text"}
            placeholder={"Name of the campaign"}
            required={true}
            className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
            Description
          </label>
        </div>
        <div className="col-span-2">
          <input
            {...form?.register("description")}
            type={"text"}
            placeholder={"Description of the campaign"}
            required={true}
            className="block w-full rounded-md shadow-sm border-divider sm:text-sm focus:ring-primary focus:border-primary"
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
            Is active
          </label>
        </div>
        <div className="col-span-2">
          <div className="flex items-center w-full">
            <label className="flex items-center space-x-2 cursor-pointer">
              <div>No</div>
              <div className="relative">
                {!form && (
                  <>
                    <input type="checkbox" className="sr-only" />
                    <div className={clsx("block h-8 rounded-full w-14", data["isEnabled"] ? "bg-primary" : "bg-gray-400")}></div>
                    <div
                      className={clsx(
                        "absolute w-6 h-6 transition bg-white rounded-full left-1 top-1",
                        data["isEnabled"] ? "transform translate-x-full" : ""
                      )}
                    ></div>
                  </>
                )}
                {form && (
                  <Controller
                    name={"isEnabled"}
                    defaultValue={true}
                    control={form?.control}
                    render={({ field }) => (
                      <>
                        <input
                          type="checkbox"
                          // onChange={(e: ChangeEvent<HTMLElement>) => {
                          //   e.preventDefault();
                          //   data.isEnabled ? archiveRef.current!.open(data) : onEnable(data);
                          // }}

                          onChange={(event) => {
                            field.onChange(event.target.checked);
                          }}
                          className="sr-only"
                        />
                        <div className={clsx("block h-8 rounded-full w-14", data["isEnabled"] ? "bg-primary" : "bg-gray-400")}></div>
                        <div
                          className={clsx(
                            "absolute w-6 h-6 transition bg-white rounded-full left-1 top-1",
                            data["isEnabled"] ? "transform translate-x-full" : ""
                          )}
                        ></div>
                      </>
                    )}
                  />
                )}
              </div>
              <div>Yes</div>
            </label>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
            Filter
          </label>
        </div>
        <div className="col-span-2">
          <QueryBuilder
            enableDragAndDrop
            fields={queryBuilderFields}
            query={data.condition as any}
            onQueryChange={(q) => {
              form?.setValue("condition", q as any);
            }}
            showCombinatorsBetweenRules
            controlClassnames={tailwindClassNames}
            controlElements={{
              addGroupAction: props => (props.level === 0 ? <ActionElement {...props} /> : null),
              valueEditor: ValueEditor
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
            Parameters
          </label>
        </div>
        <div className="col-span-2">
          {form && (
            <Controller
              name={"parameters"}
              control={form.control}
              render={({ field }) => {
                return <ParametersSelector isMulti value={field.value} onChange={(values) => field.onChange(values)} />;
              }}
            />
          )}
        </div>
      </div>
      <>
        {form && data?.id && (
          <div className="grid grid-cols-3 gap-4 px-6 py-2 mb-3">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                Variations
              </label>
              {campaignVariations?.data?.length > 0 && (
                <div className="m-5">
                  <Chart {...chartConfig} />
                </div>
              )}
            </div>
            <div className="col-span-2">
              <>
                <DataGridForm
                  ref={variationFormRef}
                  title={(form) => {
                    const data = form.getValues();
                    return data.id ? `Edit a Variation` : "Create a Variation";
                  }}
                  onSubmit={onSubmitVariationForm}
                  valueInjectFilters={{
                    condition: (v: Object) => JSON.stringify(v, undefined, 2),
                  }}
                >
                  {(form) => <VariationForm form={form} parameters={parametersArray} />}
                </DataGridForm>
                <div>
                  {campaignVariations?.data?.map((variation: any, index: any) => (
                      <div key={index} className="flex flex-row justify-between">
                        <VariationAccordionForm
                          form={form || undefined}
                          parameters={parametersArray}
                          optionIndex={index}
                          variation={variation}
                          onVariationDelete={onVariationDelete}
                          variationFormRef={variationFormRef}
                          onSubmitVariationForm={onSubmitVariationForm}
                        />
                      </div>
                    ))}
                  <button
                    onClick={() => {
                      // setDefaultParameters();
                      variationFormRef.current!.open();
                    }}
                    type="button"
                    className="py-2 text-sm font-medium text-left text-black underline decoration-solid decoration-black decoration-2"
                  >
                    Add variation
                  </button>
                </div>
              </>
            </div>
          </div>
        )}
      </>
    </>
  );
};
