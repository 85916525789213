import { FindStoresResponse } from "src/app/sdk";
import {
  CreateStoreRequest,
  FindAtpStoresRequest,
  FindStoresRequest,
  StoreObject,
  UpdateStoreRequest,
} from "src/app/sdk";
import { cast, flow, getEnv, types } from "mobx-state-tree";
import { RestaurantModel } from "src/app/models/restaurant.model";
import { RootSdkType } from "src/app/stores/root.store";
import { createNumberEntityReference, createNumberEntityStore } from "src/app/utils/number-entity.store";
import { PaginatedModels } from "../models/paginated-models.model";

export const RestaurantReference = createNumberEntityReference("restaurants", RestaurantModel);

export const RestaurantsStore = createNumberEntityStore("Restaurant", RestaurantModel)
  .props({
    restaurants: types.maybe(PaginatedModels(RestaurantReference)),
  })
  .actions((self) => ({
    fetchEntity: flow(function* (restaurantId: number) {
      const { microservice } = getEnv<RootSdkType>(self);

      const result: StoreObject = yield microservice.stores.getOne(restaurantId);

      return RestaurantModel.create({
        rawRestaurant: result,
        ...(result as any),
      });
    }),
    findRestaurants: flow(function* (findStoresData: FindStoresRequest) {
      const { microservice } = getEnv<RootSdkType>(self);
      const result: FindStoresResponse = yield microservice.stores.getAll(findStoresData);

      const restaurants = result.items.map((restaurant) => {
        const entityModel = self.cacheEntity(
          RestaurantModel.create({
            rawRestaurant: restaurant,
            ...(restaurant as any),
          }),
          true
        );
        return entityModel.id;
      });

      self.restaurants = cast({
        ...result,
        data: cast(restaurants as any),
      });

      return self.restaurants as PaginatedModels<typeof RestaurantModel>;
    }),
    findAtpRestaurants: flow(function* (findAtpStoresData?: FindAtpStoresRequest) {
      const { microservice } = getEnv<RootSdkType>(self);
      try {
        const result: StoreObject[] = yield microservice.stores.getAllAtp(findAtpStoresData);
        return result;
      } catch (error) {
        console.error("ERROR FETCHING ATP STORES:", error);
        throw error;
      }
    }),
    createRestaurant: flow(function* (createRestaurantData: CreateStoreRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.stores.create(createRestaurantData);
      } catch (error) {
        console.error("ERROR CREATING STORE:", error);
        throw error;
      }
    }),
    updateRestaurant: flow(function* (restaurantId, updateRestaurantData: UpdateStoreRequest) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.stores.update(restaurantId, updateRestaurantData);
      } catch (error) {
        console.error("ERROR UPDATING STORE:", error);
        throw error;
      }
    }),
    deleteRestaurant: flow(function* (restaurantId: number) {
      const { microservice } = getEnv<RootSdkType>(self);

      try {
        return yield microservice.stores.delete(restaurantId);
      } catch (error) {
        console.error("ERROR DELETING STORE:", error);
        throw error;
      }
    }),
  }));

export const restaurantsStore = RestaurantsStore.create();
