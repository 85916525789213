import { CreateVariationRequest, UpdateCampaignRequest, UpdateVariationRequest } from "src/app/sdk";
import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import { useCallback } from "react";
import { useAsync } from "react-async";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { CampaignForm } from "src/app/components/Campaigns/CampaignForm";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "src/app/contexts/store.context";
import { extractErrorMessage } from "src/app/utils/error";

interface FormProps {
  campaign: any;
  parameters: any;
  campaignVariations?: any;
  onSubmit: any;
  onSubmitVariationForm?: any;
  onVariationDelete?: any;
  children: (methods: UseFormReturn<FieldValues>) => React.ReactNode;
}

const Form = ({ campaign, parameters, campaignVariations, onSubmit, onSubmitVariationForm, onVariationDelete, children }: FormProps) => {
  const form = useForm({
    defaultValues: campaign
  });

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <CampaignForm
        form={form}
        parameters={parameters}
        campaignVariations={campaignVariations}
        onSubmitVariationForm={onSubmitVariationForm}
        onVariationDelete={onVariationDelete}
      />
      {children(form)}
    </form>
  );
};

interface CampaignViewParams {
  campaignId: string;
}

export const CampaignEdit = observer(() => {
  const history = useHistory();
  const {
    campaigns: campaignsStore,
    variations: variationsStore,
    parameters: parametersStore,
  } = useStore();

  const { getEntityAsync: getCampaign } = campaignsStore;

  const { findParameters } = parametersStore;

  const { campaignId } = useParams<CampaignViewParams>();

  const fetchCampaign = useCallback(async () => {
    return getCampaign(campaignId);
  }, [getCampaign, campaignId]);

  const fetchParams = useCallback(async () => {
    return findParameters({ limit: 1000, isEnabled: true });
  }, [findParameters]);

  const fetchCampaignVariations = useCallback(async () => {
    const variations = await variationsStore.getVariations(campaignId)

    return variations.map(_ => ({
      id: _.id,
      name: _.name,
      description: _.description,
      values: _.values,
      weight: _.weight,
      isDefault: _.isDefault,
      campaignId: _.campaignId
    }));
  }, [variationsStore, campaignId]);

  const campaign = useAsync({
    promiseFn: fetchCampaign,
  });

  const parameters = useAsync({
    promiseFn: fetchParams,
  });

  const campaignVariations = useAsync({
    promiseFn: fetchCampaignVariations
  });

  if (!campaign?.data || !parameters?.data || !campaignVariations.data) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen pt-16 pb-12 mt-48 bg-white">
        <LoadingSpinner type="TailSpin" color="black" height="50" />
      </div>
    );
  }

  const updateVariations = async (campaign: any) => {
    try {
      const campaignParameters: string[] = campaign.parameters || []
      const variations = await variationsStore.getVariations(campaign.id);
      const defaultValues = parameters.data?.data
        .filter(_ => campaignParameters.includes(_.id))
        .reduce((result: { [key: string]: any }, parameter) => {
          result[parameter.id] = parameter.defaultValue;
          return result;
        }, {}) || {};

      for (const variation of variations) {
        const variationValues = campaignParameters.reduce((result, parameter) => {
          result[parameter] = variation.values?.[parameter] || defaultValues[parameter];
          return result;
        }, {} as { [key: string]: any });

        const updateVariationRequest: UpdateVariationRequest = {
          name: variation.name,
          values: variationValues,
        };

        await variationsStore.updateVariation(campaign.id, variation.id, updateVariationRequest);
      }
    } catch (error: any) {
      toast.error(`Error updating variations for campaign ${campaign.name} : ${extractErrorMessage(error)}`);
    }
  };

  const onSubmit = async (data: any) => {
    const conditionData = typeof data.condition === "string" ? JSON.parse(data.condition) : data.condition;
    const selectedParameters: string[] = data.parameters.filter((item: boolean | string) => item !== false) || [];

    const updateCampaignData: UpdateCampaignRequest = {
      name: data.name,
      description: data.description,
      isEnabled: data.isEnabled,
      condition: conditionData || {},
      parameters: selectedParameters
    };

    try {
      const update = await campaignsStore.updateCampaign(data.id, updateCampaignData);
      await updateVariations(update)
      toast.success(`Campaign '${update.name}' successfully updated`);
      campaignVariations.reload();
      campaign.reload();
    } catch (error: any) {
      toast.error(`Error updating campaign ${data.name} : ${extractErrorMessage(error)}`);
    }
  };

  const onCancel = async () => {
    try {
      const campaign = await getCampaign(campaignId);
      await updateVariations(campaign)
    } finally {
      history.goBack();
    }
  };

  const onSubmitVariationForm = async (data: any) => {
    const requestData = {
      name: data.name,
      description: data.description,
      values: data.values,
      weight: parseInt(data.weight),
      isDefault: data.isDefault === "true",
    };

    if (!!data.id) {
      const updateVariationData: UpdateVariationRequest = requestData;
      try {
        await variationsStore.updateVariation(campaignId, data.id, updateVariationData);
        toast.success(`Successfully updated variation '${data.name}' `);
      } catch (error: any) {
        toast.error(`Error updating variation '${data.name}' : ${extractErrorMessage(error)}`);
      }
    } else {
      const createVariationData: CreateVariationRequest = requestData;
      try {
        await variationsStore.createVariation(campaignId, createVariationData);
        toast.success(`Successfully created variation '${data.name}' `);
      } catch (error: any) {
        toast.error(`Error creating variation '${data.name}' : ${extractErrorMessage(error)}`)
      }
    }
    campaignVariations.reload();
  };

  const onVariationDelete = async (data: any) => {
    try {
      await variationsStore.deleteVariation(data.campaignId, data.id);
      toast.success("Variation deleted")
    } catch (error: any) {
      toast.error(`Error deleting variation '${data.name}' : ${extractErrorMessage(error)}`);
    }
    campaignVariations.reload();
  };

  return (
    <HeaderLayout
      left={
        <button
          type="button"
          onClick={() => history.push(campaign.data?.isEnabled ? "/campaigns/active" : "/campaigns/archived", { from: "campaigns" })}
        >
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          Back
        </button>
      }
      title="Campaigns"
    >
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
      <div className="flex flex-col w-full px-4 py-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
          <div className="xl:grid xl:grid-cols-2">
            <div className="xl:col-span-2 xl:pr-4 xl:border-gray-200">
              <div className="p-4 bg-white rounded shadow-md">
                <Form
                  campaign={campaign.data}
                  parameters={parameters}
                  campaignVariations={campaignVariations}
                  onSubmit={onSubmit}
                  onSubmitVariationForm={onSubmitVariationForm}
                  onVariationDelete={onVariationDelete}
                >
                  {() => (
                    <div className="flex-shrink-0 px-2 py-3 border-t border-divider sm:px-6">
                      <div className="flex justify-between space-x-3">
                        <button
                          type="button"
                          className="px-4 py-2 text-sm font-medium bg-white rounded-md text-info hover:bg-white-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          onClick={() => onCancel()}
                        >
                          Cancel
                        </button>
                        <input
                          type="submit"
                          value="Save"
                          className="ml-2 inset-y-0 right-0 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                        />
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderLayout>
  );
});
