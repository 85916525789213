import axios, { AxiosInstance } from 'axios';
import { RecommendationSdk } from '../../recommendation-sdk';
import {
  CreateDashboardRequest,
  DashboardObject,
  FindDashboardsRequest,
  FindDashboardsResponse,
  UpdateDashboardRequest,
} from './models';

export class Dashboards {
  protected readonly axiosInstance: AxiosInstance;

  constructor(sdk: RecommendationSdk) {
    this.axiosInstance = axios.create({
      baseURL: sdk.endpoint,
      withCredentials: true,
    });
  }

  /**
   * Get All Dashboards
   */
  async getAll(options?: FindDashboardsRequest): Promise<FindDashboardsResponse> {
    return this.axiosInstance
      .get(`/dashboards`, {
        params: {
          skip: options?.skip,
          limit: options?.limit,
          query: options?.query,
        },
      })
      .then(res => res.data);
  }

  /**
   * Get Dashboard by Id
   * @param {number} dashboardId Id of Dashboard
   */
  async getOne(dashboardId?: number): Promise<DashboardObject> {
    return this.axiosInstance.get(`/dashboards/${dashboardId}`).then(res => res.data);
  }

  /**
   * Create a new Dashboard
   * @param {CreateDashboardRequest} dashboardData Data for create dashboard
   */
  async create(dashboardData: CreateDashboardRequest): Promise<DashboardObject> {
    return this.axiosInstance.post(`/dashboards`, dashboardData).then(res => res.data);
  }

  /**
   * Update a dashboard
   * @param {number} dashboardId Id of dashboard
   * @param {UpdateDashboardRequest} dashboardData New data for update dashboard
   */
  async update(
    dashboardId: number,
    dashboardData: UpdateDashboardRequest,
  ): Promise<DashboardObject> {
    return this.axiosInstance
      .patch(`/dashboards/${dashboardId}`, dashboardData)
      .then(res => res.data);
  }

  /**
   * Delete a dashboard
   * @param dashboardId Id of dashboard
   */
  async delete(dashboardId: number): Promise<DashboardObject> {
    return this.axiosInstance.delete(`/dashboards/${dashboardId}`).then(res => res.data);
  }
}
