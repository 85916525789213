import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useAsync } from "react-async";
import { useHistory, useParams } from "react-router-dom";
import { LoadingSpinner } from "src/app/components/Shared/LoadingSpinner/LoadingSpinner";
import { useStore } from "src/app/contexts/store.context";
import { HeaderLayout } from "src/app/components/Layout/HeaderLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FieldValues, SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import { RestaurantForm } from "src/app/components/Restaurants/RestaurantForm";
import { UpdateStoreRequest } from "src/app/sdk";
import toast from "react-hot-toast";
import { extractErrorMessage } from 'src/app/utils/error';

interface RestaurantViewParams {
  storeId: string;
}

type FormProps<TFormValues extends FieldValues> = {
  onSubmit: SubmitHandler<TFormValues>;
  restaurant: any;
  children: (methods: UseFormReturn<FieldValues>) => React.ReactNode;
};

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({ onSubmit, children, restaurant }: FormProps<TFormValues>) => {
  const methods = useForm({
    defaultValues: restaurant || {},
  });

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <RestaurantForm form={methods} />
      {children(methods)}
    </form>
  );
};

  function limitDigit(value: number): number {
    return Number(value.toFixed(6));
  }

export const RestaurantEdit = observer(() => {
  const history = useHistory();
  const { restaurants: restaurantsStore } = useStore();
  const { getEntityAsync: getRestaurant } = restaurantsStore;

  const { storeId } = useParams<RestaurantViewParams>();
  const parsedId = parseInt(storeId);

  const fetchRestaurant = useCallback(async () => {
    return getRestaurant(parsedId);
  }, [getRestaurant, parsedId]);

  const { data } = useAsync({
    promiseFn: fetchRestaurant,
  });

  const restaurant = useAsync({
    promiseFn: fetchRestaurant,
  });

  if (!restaurant?.data) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen pt-16 pb-12 mt-48 bg-white">
        <LoadingSpinner type="TailSpin" color="black" height="50" />
      </div>
    );
  }
  const onSubmit = async (data: any) => {
    const updateRestaurantData: UpdateStoreRequest = {
      name: data.name,
      atpStoreCode: data.atpStoreCode ? data.atpStoreCode : null,
      location: { latitude: limitDigit(+data.location.latitude), longitude: limitDigit(+data.location.longitude) },
    };

    try {
      const update = await restaurantsStore.updateRestaurant(data.id, updateRestaurantData);
      toast.success(`Successfully updated store '${update.name}' `);
      history.push(`/stores`);
    } catch (error: any) {
      toast.error(`Error updating store '${data.name}' : ${extractErrorMessage(error)}`);
    }
  };

  return (
    <HeaderLayout
      left={
        <button type="button" onClick={() => history.goBack()}>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          Back
        </button>
      }
      title="Stores"
    >
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        {!data ? (
          <div className="flex items-center justify-center mt-48">
            <LoadingSpinner type="TailSpin" color="black" height="50" />
          </div>
        ) : (
          <div className="flex flex-col w-full px-4 py-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
            <div className="xl:grid xl:grid-cols-2">
              <div className="xl:col-span-2 xl:pr-4 xl:border-gray-200">
                <div className="p-4 bg-white rounded shadow-md">
                  <Form restaurant={restaurant.data} onSubmit={onSubmit}>
                    {() => (
                      <div className="flex-shrink-0 px-2 py-3 border-t border-divider sm:px-6">
                        <div className="flex justify-between space-x-3">
                          <button
                            type="button"
                            className="px-4 py-2 text-sm font-medium bg-white rounded-md text-info hover:bg-white-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            onClick={() => history.goBack()}
                          >
                            Cancel
                          </button>
                          <input
                            type="submit"
                            value="Save"
                            className="ml-2 inset-y-0 right-0 inline-flex items-center px-4 py-2.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-primary hover:bg-primary-active focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-active"
                          />
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </HeaderLayout>
  );
});
