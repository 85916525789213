import {
  CreateVariationRequest,
  GetVariationsRequest,
  UpdateVariationRequest,
  VariationObject,
} from './models';
import axios, { AxiosInstance } from 'axios';
import { RecommendationSdk } from '../../../recommendation-sdk';
import { PaginatedResult } from '../../../common';

export class Variations {
  protected readonly axiosInstance: AxiosInstance;

  constructor(sdk: RecommendationSdk) {
    this.axiosInstance = axios.create({
      baseURL: sdk.endpoint,
      withCredentials: true,
    });
  }

  /**
   * Get all variations, results are paginated
   * @param {string} campaignId Id of the variation's campaign
   * @param {GetVariationsRequest} pagination data
   */
  async getAll(
    campaignId: string,
    options?: GetVariationsRequest,
  ): Promise<PaginatedResult<VariationObject>> {
    return this.axiosInstance
      .get(`optimize/campaigns/${campaignId}/variations`, {
        params: {
          skip: options?.skip,
          limit: options?.limit,
          query: options?.query,
        },
      })
      .then(res => res.data);
  }

  /**
   * Get variation by id
   * @param {string} campaignId Id of the variation's campaign
   * @param {string} variationId Id of the variation
   */
  async getOne(campaignId: string, variationId?: string): Promise<VariationObject> {
    return this.axiosInstance
      .get(`optimize/campaigns/${campaignId}/variations/${variationId}`)
      .then(res => res.data);
  }

  /**
   * Create a new variation
   * @param {string} campaignId Id of the variation's campaign
   * @param {CreateVariationRequest} variationData Data needed to create the variation
   */
  async create(
    campaignId: string,
    variationData: CreateVariationRequest,
  ): Promise<VariationObject> {
    return this.axiosInstance
      .post(`optimize/campaigns/${campaignId}/variations`, variationData)
      .then(res => res.data);
  }

  /**
   * Update a variation
   * @param {string} campaignId Id of the variation's campaign
   * @param {string} variationId Id of variation
   * @param {UpdateVariationRequest} variationData New data to update the variation
   */
  async update(
    campaignId: string,
    variationId: string,
    variationData: UpdateVariationRequest,
  ): Promise<VariationObject> {
    return this.axiosInstance
      .patch(`optimize/campaigns/${campaignId}/variations/${variationId}`, variationData)
      .then(res => res.data);
  }

  /**
   * Delete a variation
   * @param {string} campaignId Id of the variation's campaign
   * @param {string} variationId Id of the variation
   */
  async delete(campaignId: string, variationId: string): Promise<VariationObject> {
    return this.axiosInstance
      .delete(`optimize/campaigns/${campaignId}/variations/${variationId}`)
      .then(res => res.data);
  }
}
